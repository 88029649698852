import LoginUtil from "./LoginUtil";

const template = <HTMLTemplateElement>document.createElement('template') as HTMLTemplateElement
template.innerHTML = `
        <div id="warning-message" class="warning-message">
            <div class="warning-message-icon-container">
                <img alt="warning-message" src="/auth/img/warningicon.svg"/>
            </div>
        </div>
`

export default class FlashMessage extends LoginUtil {
    _flashMessage: string
    headingContainer: HTMLElement
    slot: HTMLElement
    errorFlash: HTMLElement
    once: boolean

    constructor() {
        super();
        this._flashMessage = ''
        this.headingContainer = document.getElementById('heading-container') as HTMLElement
        this.slot = document.createElement('p') as HTMLElement
        this.errorFlash = document.createElement('div') as HTMLElement
        this.once = false
    }

    get flashMessage(): string {
        return this._flashMessage
    }

    set flashMessage(value) {
        // TODO: Make class support multiple flash types.
        this._flashMessage = value;
    }

    /**
     * This function updates the data-message element.
     * @param message
     */
    setSlot(message: string) {
        const warningMessage = document.getElementById('warning-message') as HTMLElement
        this.slot.setAttribute('id', 'data-message')
        this.slot.innerHTML = message
        warningMessage.appendChild(this.slot)
    }

    /**
     * This element updates the id of the parent element.
     * @param message
     */
    flashIdUpdate(message: string) {
        if (message.includes('deactivated')) {
            this.errorFlash.setAttribute('id', 'account-deactivated')
        } else if (message.includes('locked')) {
            this.errorFlash.setAttribute('id', 'account-locked')
        } else if (message.includes('match')) {
            this.errorFlash.setAttribute('id', 'incorrect-login-credentials')
        }
    }

    /**
     * This function creates the flash message
     * @param message
     */
    flash(message: string) {
        if (!this.once) this.render()
        this.errorFlash.setAttribute('class', 'warning-message-container')
        this.headingContainer.prepend(this.errorFlash)
        this.setSlot(message)

    }

    render() {
        this.errorFlash.appendChild(template.content.cloneNode(true))
        this.once = true
    }

    close(element: HTMLElement): void {
        if (element) {
            element.remove();
        }
    }
}
