import LoginForm from "./LoginForm";

/**
 * Handler class for the display of Users
 */
export default class UserDisplayHandler extends LoginForm{

    private user: HTMLElement
    private userEmail: HTMLElement
    private readonly email: HTMLInputElement

    constructor(user: HTMLElement, userEmail: HTMLElement, email: HTMLInputElement) {
        super()
        this.user = user
        this.userEmail = userEmail
        this.email = email
    }

    /**
     * Function responsible for hiding the signing in as label.
     */
    hideSigninAs() {
        this.user.classList.add(this.dnone)
        this.userEmail.textContent = ''
    }

    /**
     * Function responsible for showing the signing in as email value.
     */
    showSignInAs() {
        this.user.classList.remove(this.dnone)
        if ("value" in this.email) {
            this.userEmail.textContent = this.email.value
        }
    }
}