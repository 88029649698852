import LoginForm from "./LoginForm";

/**
 * Handler class for the display of the Loader
 */
export default class LoaderDisplayHandler extends LoginForm {

    private spinner: HTMLElement
    private signinBox: HTMLElement

    constructor(spinner: HTMLElement, signinBox: HTMLElement) {
        super()
        this.spinner = spinner
        this.signinBox = signinBox
    }

    /**
     * Function responsible for hiding the spinner on load.
     */
    spinnerHide() {
        this.spinner.classList.add(this.dnone)
        this.signinBox.classList.remove(this.opaque)
    }

    /**
     * Function responsible for showing the spinner on load.
     */
    spinnerShow() {
        this.spinner.classList.remove(this.dnone)
        this.signinBox.classList.add(this.opaque)
    }
}