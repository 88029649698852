import '../assets/app.scss'
import '../assets/pages/login.scss'
import UserDisplayHandler from "../services/UserDisplayHandler"
import LoaderDisplayHandler from "../services/LoaderDisplayHandler"
import SubmitUtilities from "../services/SubmitUtilities"
import LoginUtil from "../services/LoginUtil"
import Validator from "../services/Validator"
import Flash from "../services/FlashMessage"
import * as Sentry from "@sentry/browser";

window.addEventListener("load", () => {

    let state = 'ssoCheck'
    const form = <HTMLFormElement> document.getElementById('form')
    const email = <HTMLInputElement> document.getElementById('email')
    const password = <HTMLInputElement> document.getElementById('password')
    const passwordFormControl = <HTMLElement>document.getElementById('password-form-control')
    const emailFormControl = <HTMLElement>document.getElementById('email-form-control')
    const spinner = <HTMLElement>document.getElementById('spinner')
    const signinBox = <HTMLElement>document.getElementById('sign-in')
    const signinButton = <HTMLElement>document.getElementById('sign_in')
    const user = <HTMLElement>document.getElementById('user-info')
    const userEmail = <HTMLElement>document.getElementById('user-email')
    const switchAccount = <HTMLInputElement>document.getElementById('switch-account')
    const activateAccount = <HTMLElement>document.getElementById('activate-account-link-container')
    const activateAccountLink = <HTMLElement>document.getElementById('activate-account')
    const forgotPassword = <HTMLElement>document.getElementById('forgot-password-label')
    const help = <HTMLElement>document.getElementById('help')
    const csrfName = <HTMLInputElement>document.getElementById('csrf_name')
    const csrfValue = <HTMLInputElement>document.getElementById('csrf_value')
    const successExit = <HTMLElement>document.getElementById('success-message--exit')
    const successMessageContainer = <HTMLElement>document.getElementById('success-message-container')
    const warningMessage = <HTMLElement>document.getElementById('warning-message')
    const legalField = <HTMLInputElement>document.getElementById('legal')
    const titleH3 = <HTMLElement>document.getElementById('title')
    const TandCContainer = <HTMLElement>document.getElementById('tc-container')
    const terms = <HTMLElement> document.getElementById('terms-link')
    const supplierTerms = <HTMLElement> document.getElementById('supplier-terms-link')

    const validator = new Validator()
    const loginUtil = new LoginUtil()
    const userDisplay = new UserDisplayHandler(user, userEmail, email)
    const loaderDisplay = new LoaderDisplayHandler(spinner, signinBox)
    const submitUtil = new SubmitUtilities(passwordFormControl, emailFormControl, switchAccount, activateAccount)
    const flash = new Flash()

    function dynamicField() {
        submitUtil.switchFieldPassword()
        userDisplay.showSignInAs()
        submitUtil.resetTabOrder([password, forgotPassword, signinButton, activateAccountLink, switchAccount, help])
    }

    if (successExit) {
        successExit.addEventListener('click', () => {
            successMessageContainer.classList.add(loginUtil.dnone)
        })
    }

    email.addEventListener('change', () => {
        validator.clearError(email)
    })

    password.addEventListener('change', () => {
        validator.clearError(password)
        validator.clearFieldErrorMsg(password)
    })

    if (successExit) {
        successExit.addEventListener('click', () => {
            successMessageContainer.classList.add(loginUtil.dnone)
        })
    }

    form.addEventListener('submit', async (e) => {
        validator.clearError(password)
        validator.clearFieldErrorMsg(password)
        e.preventDefault()
        loaderDisplay.spinnerShow()
        flash.close(successMessageContainer)

        if (state === 'ssoCheck') {
            if (email.value !== '') {
                flash.close(warningMessage)
                const query = 'state=' + state
                    + '&email=' + encodeURIComponent(email.value)
                    + '&csrf_name=' + encodeURIComponent(csrfName.value)
                    + '&csrf_value=' + encodeURIComponent(csrfValue.value)
                await fetch(window.location.href, {
                    method: 'POST',
                    body: query,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    redirect: 'follow',
                }).then(async (res) => {
                    if (res.redirected) {
                        window.location.href = res.url
                    }
                    if (res.status === 403) { // csrf token expired
                        window.location.href = '/auth/login'
                        return;
                    }
                    await res.json().then((data) => {
                        if (data.ssoUser === true && data.ssoEnabled === true) {
                            window.location.href = data.ssoUrl
                        } else if (data.success === true) {
                            loaderDisplay.spinnerHide()
                            state = 'password'
                            dynamicField()
                        } else {
                            loaderDisplay.spinnerHide()
                            validator.fieldError(email)
                            validator.emptyFieldErrorMsg(email)
                        }
                    })
                }).catch((err) => {
                    Sentry.captureException(err)
                    flash.flash('There was an issue with your request. please try again.')
                    loaderDisplay.spinnerHide()
                    validator.fieldError(email, ' ')
                    email.value = ''
                })
            } else {
                loaderDisplay.spinnerHide()
                validator.fieldError(email)
                validator.emptyFieldErrorMsg(email)
            }
        } else {
            const query = 'state=' + state
                + '&email=' + encodeURIComponent(email.value)
                + '&password=' + encodeURIComponent(password.value)
                + '&csrf_name=' + encodeURIComponent(csrfName.value)
                + '&csrf_value=' + encodeURIComponent(csrfValue.value)
                + '&legal=' + encodeURIComponent(legalField.value)
            await fetch(window.location.href, {
                method: 'POST',
                body: query,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                redirect: 'follow',
            }).then(async (res) => {
                if (res.redirected) {
                    window.location.href = res.url
                }
                if (res.status === 403) { // csrf token expired
                    window.location.href = '/auth/login'
                    return
                }
                await res.json().then((data) => {
                    if (data.legalRequired) {
                        state = 'legal'
                        showLegalForm()
                    } else if (typeof data.intendedUri !== 'undefined' && data.intendedUri !== '') {
                        window.location.href = data.intendedUri
                    } else if (data.success === true) {
                        window.location.href = '/'
                    } else if (data.expired === true) {
                        window.location.href = '/auth/expired'
                    } else {
                        submitUtil.clearField(password)
                        loaderDisplay.spinnerHide()
                        if (data.message) {
                            if (!data.message.includes('locked')) {
                                validator.ajaxResponseMessage(password, data.message)
                                validator.fieldError(password)
                                validator.emptyFieldErrorMsg(password)
                            } else {
                                flash.flash(data.message)
                                validator.clearError(password)
                                validator.clearFieldErrorMsg(password)
                            }
                        }
                    }
                });
            }).catch((err) => {
                Sentry.captureException(err)
                loaderDisplay.spinnerHide()
                dynamicField()
                validator.fieldError(password)
                validator.clearFieldErrorMsg(password)
            })
        }
    })

    switchAccount.addEventListener('click', (e) => {
        e.preventDefault()
        userDisplay.hideSigninAs()
        state = 'ssoCheck'
        email.value = '' // this may be wrong
        password.value = ''
        submitUtil.switchFieldEmail()
        validator.clearError(email)
        validator.clearError(password)
        validator.clearFieldErrorMsg(password)
    })

    function showLegalForm () {
        loaderDisplay.spinnerHide()
        user.hidden = true
        password.type = 'hidden'
        emailFormControl.classList.add('d-none')
        passwordFormControl.classList.add('d-none')
        signinButton.innerText = 'Accept'
        legalField.value = 'true'
        titleH3.innerText = 'Terms and Conditions'
        switchAccount.classList.add('d-none')
        TandCContainer.classList.remove('d-none')
        submitUtil.resetTabOrder([terms, supplierTerms, signinButton, help])
        titleH3.tabIndex = -1
        titleH3.focus()
    }
})
