/**
 * Class responsible for generic properties
 */
export default abstract class LoginForm {

    dnone: string
    protected opaque: string
    protected checkMark: string
    protected bullet: string
    hasError: string

    protected constructor() {
        this.dnone = 'd-none'
        this.opaque = 'opacity05'
        this.checkMark = 'checkmark'
        this.bullet = 'bullet'
        this.hasError = 'has-error'
    }

    /**
     * Test functions to check console output.
     */
    bark(value: string) {
        console.log(value)
    }

    /**
     * Test functions to alert.
     */
    howl() {
        alert("Enter Value")
    }
}