import LoginForm from "./LoginForm";

/**
 * Class responsible for form validation
 */
export default class Validator extends LoginForm {

    private form: HTMLFormElement
    private password: HTMLInputElement
    private password2: HTMLInputElement
    private oldPassword: HTMLInputElement
    private readonly lengthRule: HTMLElement
    private readonly upperCaseRule: HTMLElement
    private readonly lowerCaseRule: HTMLElement
    private readonly specialCharRule: HTMLElement
    private readonly numberRule: HTMLElement
    private readonly passwordsMatchRule: HTMLElement

    constructor() {
        super();
        this.form = <HTMLFormElement> document.getElementById('form')
        this.oldPassword = <HTMLInputElement>document.getElementById('oldPassword')
        this.password = <HTMLInputElement>document.getElementById('password')
        this.password2 = <HTMLInputElement>document.getElementById('password2')
        this.lengthRule = <HTMLElement>document.getElementById('length-rule')
        this.upperCaseRule = <HTMLElement>document.getElementById('upper-case-rule')
        this.lowerCaseRule = <HTMLElement>document.getElementById('lower-case-rule')
        this.specialCharRule = <HTMLElement>document.getElementById('special-char-rule')
        this.numberRule = <HTMLElement>document.getElementById('number-rule')
        this.passwordsMatchRule = <HTMLElement>document.getElementById('passwords-match-rule')
    }

    ajaxResponseMessage(input: HTMLElement, data: string) {
        const formControl = input.parentElement as HTMLElement
        const errorMessageText = formControl.querySelector('p') as HTMLElement
        errorMessageText.innerHTML = data
    }

    getFieldName(input: HTMLElement) {
        return input.id.charAt(0) + input.id.slice(1);
    }

    clearFieldErrorMsg(input: HTMLElement) {
        const formControl = input.parentElement as HTMLElement
        const errorMessageContainer = formControl.querySelector('.has-error-message') as HTMLElement
        errorMessageContainer.classList.add(this.dnone)
    }

    emptyFieldErrorMsg(input: HTMLElement, message?: string) {
        const formControl = input.parentElement as HTMLElement
        const errorMessageText = formControl.querySelector('p') as HTMLElement
        const messageContainer = formControl.querySelector('.has-error-message.d-none') as HTMLElement
        if (messageContainer) {
            messageContainer.classList.remove(this.dnone)
        }
        if (input.id == 'email') {
            errorMessageText.innerHTML = `Your ${this.getFieldName(input)} address is required.`
        }
        if (message) {
            errorMessageText.innerHTML = message
        }
    }

    fieldError(input: HTMLElement, message?: string) {
        input.classList.add(this.hasError)
        this.emptyFieldErrorMsg(input, message)
    }

    clearError(input: HTMLElement) {
        const hasErrorMessage = document.querySelector('.has-error-message') as HTMLElement
        if (!hasErrorMessage) {
            return
        }
        hasErrorMessage.classList.add(this.dnone)
        input.classList.remove(this.hasError)
    }

    inputHasError(input: HTMLElement) {
        const formControl = input.parentElement as HTMLElement
        return formControl.querySelector('.has-error-message.d-none') === null
    }

    isValid() {
        if (!this.checkPasswordsMatch()) {
            return false
        }
        if (!this.checkLength()) {
            return false
        }
        if (!this.checkNumber()) {
            return false
        }
        if (!this.checkUpperCase()) {
            return false
        }
        if (!this.checkLowerCase()) {
            return false
        }
        if (!this.checkSpecialChar()) {
            return false
        }
        return this.checkRequired();

    }

    successFunction(rule: HTMLElement) {
        rule.classList.add(this.checkMark)
        rule.classList.remove(this.bullet)
    }

    failureFunction(rule: HTMLElement) {
        rule.classList.remove(this.checkMark)
        rule.classList.add(this.bullet)
    }

    validate() {
        this.checkPasswordsMatch()
        this.checkLength()
        this.checkNumber()
        this.checkUpperCase()
        this.checkLowerCase()
        this.checkSpecialChar()
    }

    checkPasswordsMatch() {
        if (this.password.value.length && this.password2.value.length >= 1) {
            if (this.password.value === this.password2.value) {
                this.successFunction(this.passwordsMatchRule)
                return true
            }
        } 
        this.failureFunction(this.passwordsMatchRule)
        return false
    }

    checkLength() {
        if (this.password.value.length >= 9) {
            this.successFunction(this.lengthRule)
            return true
        }
        this.failureFunction(this.lengthRule)
        return false
    }

    checkNumber() {
        const regex = /^(?=.*[0-9]).+$/;
        if (regex.test(this.password.value)) {
            this.successFunction(this.numberRule)
            return true
        }
        this.failureFunction(this.numberRule)
        return false
    }

    checkUpperCase() {
        const regex = /^(?=.*[A-Z]).+$/;
        if (regex.test(this.password.value)) {
            this.successFunction(this.upperCaseRule)
            return true
        }
        this.failureFunction(this.upperCaseRule)
        return false
    }

    checkLowerCase() {
        const regex = /^(?=.*[a-z]).+$/;
        if (regex.test(this.password.value)) {
            this.successFunction(this.lowerCaseRule)
            return true
        }
        this.failureFunction(this.lowerCaseRule)
        return false
    }

    checkSpecialChar() {
        const regex = /^(?=.*[_\W]).+$/;
        if (regex.test(this.password.value)) {
            this.successFunction(this.specialCharRule)
            return true
        }
        this.failureFunction(this.specialCharRule)
        return false
    }

    checkRequired() {
        return !(this.password.value === '' || this.password2.value === '');
    }
}
