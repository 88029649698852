import LoginForm from "./LoginForm";

/**
 * Handler class for dynamic submit utilities
 */
export default class SubmitUtilities extends LoginForm {

    private passwordFormControl: HTMLElement
    private emailFormControl: HTMLElement
    private switchAccount: HTMLElement
    private activateAccount: HTMLElement

    constructor(passwordFormControl: HTMLElement, emailFormControl: HTMLElement, switchAccount: HTMLInputElement, activateAccount: HTMLElement) {
        super()
        this.passwordFormControl = passwordFormControl
        this.emailFormControl = emailFormControl
        this.switchAccount = switchAccount
        this.activateAccount = activateAccount
    }

    /**
     * Function responsible for dynamically changing fields
     */
    switchFieldPassword() {
        this.passwordFormControl.classList.remove(this.dnone)
        this.emailFormControl.classList.add(this.dnone)
        this.switchAccount.classList.remove(this.dnone)
        this.activateAccount.classList.add(this.dnone)
    }

    /**
     * Function responsible for dynamically changing fields
     */
    switchFieldEmail() {
        this.passwordFormControl.classList.add(this.dnone)
        this.emailFormControl.classList.remove(this.dnone)
        this.switchAccount.classList.add(this.dnone)
        this.activateAccount.classList.remove(this.dnone)
    }

    /**
     * Function responsible for clearing an input field.
     * @param element
     */
    clearField(element: HTMLInputElement) {
        element.value = ''
    }

    /**
     * This function is responsible for resetting the tab order. The primary element will have a tab index of 0, and any subsequent elements will add one in the order of the input array.
     * @param array - Array of elements, with the first element in focus, and corresponding elements in tabindex order.
     */
    resetTabOrder(array: HTMLElement[]) {
        array.forEach((key, value) => {
            if (value === 0) {
                key.focus()
            }
            key.tabIndex = value +1
        })
    }
}